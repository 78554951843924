import {Alert, AlertTitle, Typography} from "@mui/material";

export default function ErrorAlert({error}) {
    return (
        <Alert severity="error" variant="outlined">
            <AlertTitle>Error Occurred</AlertTitle>
            <Typography>{error['message'] || 'Please email us at contact@camba.ca'}</Typography>
        </Alert>
    )
}

import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
    Alert,
    AlertTitle, Avatar,
    Box,
    Card,
    CardContent, CardHeader,
    CardMedia,
    CircularProgress,
    Typography, useTheme
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import parseISO from 'date-fns/parseISO'
import formatDistance from 'date-fns/formatDistance'
import format from 'date-fns/format'
import Page from './page';
import {ApiContext} from './api';
import Logo from './logo.png'


export default function VerifyPage() {
    const t = useTheme();
    const apiUrl = useContext(ApiContext);
    const {memberId, membershipId} = useParams();
    const [isWorking, setIsWorking] = useState(true);
    const [error, setError] = useState();
    const [data, setData] = useState();

    useEffect(() => {
        fetch(`${apiUrl}/memberships/verify/${memberId}/${membershipId}`)
            .then(rsp => {
                if (rsp.status !== 200) {
                    if (rsp.headers.get("Content-Type") !== 'application/json') throw Error(`Unexpected error: ${rsp.statusText}`)
                }
                return rsp.json()
            })
            .catch(err => {
                setError(err);
            })
            .then(data => {
                if (data['status'] === 'ok') {
                    setData(data);
                }
                else {
                    setError(data);
                }
            })
            .finally(() => setIsWorking(false))
    }, [memberId, membershipId, apiUrl])

    const isActive = () => data.membership.state === 'activated';

    const endDate = active => {
        const s = data.membership.subscriptions.find(it => it.status === (active?'Active':'Expired'));
        if (s) {
            const d = parseISO(s.end_date);
            return `${formatDistance(d, Date.now())} ${active?"":" ago"} on ${format(d,"MMMM do, yyyy")}`
        }
        return null;
    }

    return (
        <Page>
            {isWorking && <Box display="flex" justifyContent="center">
                <CircularProgress/>
            </Box>}
            {error && <Alert severity="error">
                <AlertTitle>An Error Occurred</AlertTitle>
                <Typography>{error['message'] || 'Please email us at contact@camba.ca'}</Typography>
            </Alert> }
            {data && <Card>
                <CardContent>
                    <Box sx={{display: 'flex'}}>
                        <CardMedia component="img" image={Logo} sx={{
                            height: 128,
                            width: 'auto'
                        }}>
                        </CardMedia>
                        <Box>
                            <CardHeader title={`${data.contact.first_name} ${data.contact.last_name}`} sx={{flexGrow: 1}}
                                        subheader={data.membership.membership_level.name}
                                        avatar={ <Avatar sx={{backgroundColor:t.palette[isActive()?"primary":"secondary"].main}}>
                                            {isActive() ? <CheckIcon/> : <CloseIcon/>}
                                        </Avatar>}
                            />
                            <Box px={2}>
                                <Typography variant="subtitle2">
                                    {isActive() && `This membership is active, and will expire in ${endDate(true)}.` }
                                    {!isActive() && `This membership expired ${endDate(false)}.`}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>

            </Card>}
        </Page>
    )
}

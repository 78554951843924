import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import Logo from './images/logo.png'
import {ApiContext} from './api';
import Page from './page';
import Waiver from "./waiver";


const RedeemPage = () => {
    const theme = useTheme();
    const p = useParams()
    const apiUrl = useContext(ApiContext);
    const [accept, setAccept] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const [isLoading, setLoading] = useState(true);
    const [isRedeeming, setRedeeming] = useState(false);
    const [isInvalid, setInvalid] = useState(false);
    const [isRedeemed, setRedeemed] = useState(false);
    const [isError, setError] = useState(false);

    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const isReady = accept && !!name && !!email;

    useEffect(() => {
        if (apiUrl && p.code) {
            fetch(`${apiUrl}/memberships/coupons/${p.code}`)
                .then(rsp => {
                    if (rsp.status === 404) {
                        throw new Error(rsp.statusText);
                    }
                    else return rsp.json()
                })
                .catch(() => {
                    setInvalid(true);
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }, [apiUrl, p.code]);

    const redeem = () => {
        setRedeeming(true);
        setError(false);
        fetch(`${apiUrl}/memberships/coupons/${p.code}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name, email
            })
        })
            .then(rsp => {
                if (rsp.status !== 200) {
                    throw new Error();
                }
                return rsp.json();
            })
            .catch(() => {
                setError(true);
            })
            .then(() => {
                setRedeemed(true);
            })
            .finally(() => {
                setRedeeming(false);
            });
    }

    return (
        <Page maxWidth="lg">
            {isLoading && <CircularProgress/>}
            {!isLoading && isInvalid && <Alert severity="error">
                <AlertTitle>Invalid Gift</AlertTitle>
                <Typography>Sorry, but this coupon appears to be invalid. Please email us at <a href="mailto:contact@camba.ca">contact@camba.ca</a>.</Typography>
            </Alert> }
            {!isLoading && isError && <Alert severity="error" sx={{marginBottom: theme.spacing(1)}}>
                <AlertTitle>Oops</AlertTitle>
                <Typography>Sorry, there was an issue redeeming this membership. Please email us at <a href="mailto:contact@camba.ca">contact@camba.ca</a>.</Typography>
            </Alert> }
            {!isLoading && !isError && isRedeemed && <Alert severity="success">
                <AlertTitle>Thanks</AlertTitle>
                <Typography>Your membership has been redeemed. You will be receiving an email with a confirmation shortly. Happy Trails!</Typography>
            </Alert> }
            {!isLoading && !isInvalid && !isRedeemed && <Card sx={{padding:{xs: 1, sm:4}}}>
                <CardContent sx={{padding:0}}>
                    <Box display="flex" sx={{flexWrap: "wrap", justifyContent: "center"}}>
                        <CardMedia component="img" image={Logo} sx={{
                            height: 128,
                            width: 'auto'
                        }}>
                        </CardMedia>
                        <CardHeader title={"Redeem a CAMBA Membership"} sx={{flexGrow: 1, padding: {xs:1, sm:4}}}
                                    subheader="Congratulations, you've been gifted a CAMBA membership. We just need a bit of info, and you're good to go."/>
                    </Box>
                    <Box component="form" py={isSmall?2:4}>

                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField label="Full Name" variant="outlined" placeholder="First and last name"
                                           value={name} onChange={evt => setName(evt.target.value)} fullWidth/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField label="Email" variant="outlined" placeholder="Email address"
                                           value={email} onChange={evt => setEmail(evt.target.value)} fullWidth/>
                            </Grid>
                        </Grid>
                        <Box mt={4}>
                            <Waiver accepted={accept} onChange={setAccept}/>
                        </Box>
                    </Box>
                </CardContent>
                <CardActions sx={{flexDirection: 'row-reverse'}}>
                    {isRedeeming ? <CircularProgress sx={{fontSize: 24}}/> :
                        <>
                            <Button size="small" disabled={!isReady} onClick={redeem}>Redeem</Button>
                            {isReady && <ArrowCircleUpIcon fontSize="large" sx={{
                                color: theme.palette.primary.main,
                                transform: 'rotate(90deg)'
                            }}/>}
                        </>
                    }
                </CardActions>
            </Card>}
            {/*{!isLoading && isError && <Alert severity="error" sx={{marginTop: theme.spacing(1)}}>*/}
            {/*    <AlertTitle>Oops</AlertTitle>*/}
            {/*    <Typography>Sorry, there was an issue redeeming this gift. Please email us at <a href="mailto:contact@camba.ca">contact@camba.ca</a>.</Typography>*/}
            {/*</Alert> }*/}
        </Page>
    )
}
export default RedeemPage;

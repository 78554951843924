import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import reportWebVitals from './reportWebVitals';
import VerifyPage from './verify';
import HomePage from './home';
import GeneratePage from './generate';
import RedeemPage from "./redeem";

const theme = createTheme({
    palette: {
        primary: {
            main: '#4d8c40'
        },
        secondary: {
            main: '#f44336'
        }
    }
});

ReactDOM.render(
  <React.StrictMode>
      <CssBaseline/>
      <ThemeProvider theme={theme}>
          <Router>
              <Routes>
                  <Route path="/verify/:memberId/:membershipId" element={<VerifyPage/>}/>
                  <Route path="/generate" element={<GeneratePage/>}/>
                  <Route path="/redeem/:code" element={<RedeemPage/>}/>
                  <Route path="/" element={<HomePage/>}/>
              </Routes>
          </Router>
      </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();

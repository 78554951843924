import {useContext, useState} from 'react';
import {useTheme} from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Page from './page';
import Logo from './logo.png'
import {ApiContext} from "./api";
import ErrorAlert from "./error";


export default function GeneratePage() {
    const apiUrl = useContext(ApiContext);
    const t = useTheme();
    const [isWorking, setIsWorking] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [error, setError] = useState();
    const [id, setId] = useState("")

    const generate = () => {
        fetch(`${apiUrl}/memberships/${id}`,
            {
                method: 'PUT'
            })
            .then(rsp => {
                if (rsp.status !== 200) {
                    if (rsp.headers.get("Content-Type") !== 'application/json') throw Error(`Unexpected error: ${rsp.statusText}`)
                }
                return rsp.json()
            })
            .catch(err => {
                setError(err);
            })
            .then(data => {
                if (data['status'] === 'ok') {
                    setError(null);
                    setIsDone(true);
                }
                else {
                    setError(data);
                }
            })
            .finally(() => setIsWorking(false))
    }
    return (<Page>
        {error && <ErrorAlert error={error}/> }
        {isDone && <Alert severity="success" variant="outlined">
            <AlertTitle>Success!</AlertTitle>
        </Alert> }
        <Paper sx={{padding: t.spacing(2), marginTop: t.spacing(1)}}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                    <img src={Logo} alt="CAMBA Logo" style={{width: "100%", height: "auto"}}/>
                </Grid>
                <Grid item xs={8}>
                    <Box mb={2}>
                        <TextField variant="outlined" label="Membership ID" value={id} onChange={e => setId(e.target.value)} fullWidth/>
                    </Box>
                    <Button variant="contained" disabled={!id || isDone || isWorking} onClick={generate}>Generate</Button>
                </Grid>
            </Grid>
        </Paper>
    </Page>)
}

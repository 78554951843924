import {useMediaQuery, useTheme} from "@mui/material";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';


export default function Page({maxWidth = "sm", children}) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Container maxWidth={maxWidth} sx={{paddingLeft: {xs:1, sm:2}, paddingRight: {xs:1, sm:2}}}>
            <Box py={isSmall?2:8}>
                {children}
            </Box>
        </Container>
    )
}

import useTheme from "@mui/material/styles/useTheme";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";


const Waiver = ({accepted, onChange}) => {
    const theme = useTheme();
    return (
        <Box>
            <Typography variant="h6" textAlign="center">RELEASE OF LIABILITY, WAIVER OF CLAIMS,ASSUMPTION OF RISKS AND INDEMNITY AGREEMENT</Typography>
            <Typography variant="subtitle1" textAlign="center">(hereinafter referred to as the “Release Agreement”)</Typography>
            <Box mt={2}>
                <Typography variant="subtitle2" sx={{textTransform: 'uppercase'}}>DEFINITION</Typography>
                <Typography>
                    In this Release Agreement: the term "Mountain Biking" shall include all activities, services and use of facilities either provided by or arranged by the Releasees, including, but not limited to: orientation and instruction sessions; recreational activities undertaken during the event, tour; travel by mountain bike; all travel either within or beyond the designated boundaries, including in the backcountry and on logging roads and trails.
                </Typography>
            </Box>
            <Box mt={1}>
                <Typography variant="subtitle2" sx={{textTransform: 'uppercase'}}>ASSUMPTION OF RISKS</Typography>
                <Typography>
                    I am aware that Mountain Biking involves many inherent risks, dangers and hazards, including but not limited to: exposed rock, earth, ice or other natural objects; trees, tree wells, tree stumps, forest deadfall; holes and depressions on or beneath the trail or surface; variable and difficult weather conditions; changes or variations in the terrain which may create blind spots or areas of reduced visibility; streams and creeks; cliffs; crevasses; travel on back-country roads; snowcat roads, road-banks and cut-banks; impact or collision with other mountain bikes and vehicles, becoming lost or separated from the guides or other participants; mechanical failure of mountain bikes and related equipment; extreme and rapidly changing weather conditions; encounter with wildlife including bears; avalanches; the failure to operate a mountain bike safely or within one's own ability; negligence of other mountain bikers and other persons; AND NEGLIGENCE ON THE PART OF THE RELEASEES, INCLUDING THE FAILURE BY THE RELEASEES TO SAFEGUARD OR PROTECT ME FROM THE RISKS, DANGERS AND HAZARDS REFERRED TO ABOVE. Communication in the alpine terrain may be difficult, and in the event of an accident, rescue, medical treatment and evacuation may not be available or may be delayed. Alpine weather conditions may be extreme and can change rapidly and without warning.
                </Typography>
            </Box>
            <Box mt={1}>
                <Typography>
                    I AM AWARE OF THE RISKS, DANGERS AND HAZARDS ASSOCIATED WITH MOUNTAIN BIKING AND I FREELY ACCEPT AND FULLY ASSUME ALL SUCH RISKS, DANGERS AND HAZARDS AND THE POSSIBILITY OF PERSONAL INJURY, DEATH, PROPERTY DAMAGE OR LOSS RESULTING THEREFROM.
                </Typography>
            </Box>
            <Box mt={1}>
                <Typography variant="subtitle2" sx={{textTransform: 'uppercase'}}>RELEASE OF LIABILITY, WAIVER OF CLAIMS AND INDEMNITY AGREEMENT
                </Typography>
                <Typography>
                    In consideration of the RELEASEES agreeing to my participation in mountain biking and permitting my use of their services, equipment and other facilities, and for other good and valuable consideration, the receipt and sufficiency of which is acknowledged, I hereby agree as follows:
                </Typography>
            </Box>
            <Box mt={1}>
                <Typography variant="subtitle2" sx={{textTransform: 'uppercase'}}>RELEASE OF LIABILITY, WAIVER OF CLAIMS AND INDEMNITY AGREEMENT
                </Typography>
                <Typography>
                    In consideration of the RELEASEES agreeing to my participation in mountain biking and permitting my use of their services, equipment and other facilities, and for other good and valuable consideration, the receipt and sufficiency of which is acknowledged, I hereby agree as follows:
                </Typography>
                <Typography>
                    <ol>
                        <li>TO WAIVE ANY AND ALL CLAIMS that I have or may in the future have against the RELEASEES AND TO RELEASE THE RELEASEES from any and all liability for any loss, damage, expense or injury, including death, that I may suffer or that my next of kin may suffer, as a result of my participation in mountain biking, DUE TO ANY CAUSE WHATSOEVER, INCLUDING NEGLIGENCE, BREACH OF CONTRACT, OR BREACH OF ANY STATUTORY OR OTHER DUTY OF CARE, INCLUDING ANY DUTY OF CARE OWED UNDER THE OCCUPIERS LIABILITY ACT, ON THE PART OF THE RELEASEES, AND FURTHER INCLUDING THE FAILURE ON THE PART OF THE RELEASEES TO SAFEGUARD OR PROTECT ME FROM THE RISKS, DANGERS AND HAZARDS OF PARTICIPATING IN MOUNTAIN BIKING REFERRED TO ABOVE;</li>
                        <li>TO HOLD HARMLESS AND INDEMNIFY THE RELEASEES for any and all liability for any property damage, loss or personal injury to any third party resulting from my participation in mountain biking;</li>
                        <li>This Release Agreement shall be effective and binding upon my heirs, next of kin, executors, administrators, assigns and representatives, in the event of my death or incapacity;</li>
                        <li>This Release Agreement and any rights, duties and obligations as between the parties to this Release Agreement shall be governed by and interpreted solely in accordance with the laws of the province where the mountain biking takes place and no other jurisdiction; and</li>
                        <li>Any litigation involving the parties to this Release Agreement shall be brought solely within the province where the mountain biking takes place and shall be within the exclusive jurisdiction of the Courts of that province.</li>
                        <li>In entering into this Release Agreement I am not relying on any oral or written representations or statements made by the Releasees with respect to the safety of participating in mountain biking, other than what is set forth in this Release Agreement.</li>
                    </ol>
                </Typography>
            </Box>
            <Box mt={1}>
                <Paper variant="outlined" sx={{
                    borderColor: theme.palette.primary.main,
                    borderWidth: 4,
                    borderStyle: 'solid',
                    padding: theme.spacing(2)
                }}>
                    <Typography>
                        I CONFIRM THAT I HAVE READ AND UNDERSTOOD THIS RELEASE AGREEMENT PRIOR TO SIGNING IT, AND I AM AWARE THAT BY SIGNING THIS RELEASE AGREEMENT I AM WAIVING CERTAIN LEGAL RIGHTS WHICH I OR MY HEIRS, NEXT OF KIN, EXECUTORS, ADMINISTRATORS, ASSIGNS AND REPRESENTATIVES MAY HAVE AGAINST THE RELEASEES.
                    </Typography>
                    <FormControlLabel control={<Checkbox value={accepted} onChange={() => onChange(!accepted)}/>} label="Agree to Terms" />
                </Paper>
            </Box>
        </Box>
    )
}

export default Waiver;
